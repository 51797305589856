import _debug from "debug";
import _notvaliderror from "./errors/notvaliderror";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

var debug = _debug("tokens"),
    NotValidError = _notvaliderror;

function Decoder() {
  (this || _global)._formats = [];
}

Decoder.prototype.use = function (fn) {
  (this || _global)._formats.push(fn);
};

Decoder.prototype.decode = function (data, options, cb) {
  if (typeof options == "function") {
    cb = options;
    options = undefined;
  }

  options = options || {};
  var self = this || _global,
      stack = (this || _global)._formats,
      idx = 0;

  function next(err, token, extra) {
    if (err || token) {
      return cb(err, token, extra);
    }

    var layer = stack[idx++];

    if (!layer) {
      return cb(new NotValidError("Invalid token"));
    }

    try {
      debug("decode %s", layer.name || "anonymous");
      var arity = layer.length;

      if (arity == 3) {
        // async with options
        layer(data, options, next);
      } else if (arity == 2) {
        // async
        layer(data, next);
      } else {
        var t = layer(data);
        next(null, t);
      }
    } catch (ex) {
      next(ex);
    }
  }

  next();
};

exports = Decoder;
export default exports;
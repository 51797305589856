import _encoder from "./encoder";
import _decoder from "./decoder";
import _sat from "./decode/sat";
import _oauthIntrospection from "./decode/oauthIntrospection";
import _sat2 from "./encode/sat";
var exports = {};
var Encoder = _encoder,
    Decoder = _decoder;
exports.Encoder = Encoder;
exports.Decoder = Decoder;
exports.decode = {};
exports.decode.sat = _sat;
exports.decode.oauthIntrospection = _oauthIntrospection;
exports.encode = {};
exports.encode.sat = _sat2;
export default exports;
export const decode = exports.decode,
      encode = exports.encode;
const _Encoder = exports.Encoder,
      _Decoder = exports.Decoder;
export { _Encoder as Encoder, _Decoder as Decoder };
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * `NotValidError` error.
 *
 * @api public
 */
function NotValidError(message) {
  Error.call(this || _global);
  Error.captureStackTrace(this || _global, arguments.callee);
  (this || _global).name = "NotValidError";
  (this || _global).message = message;
  (this || _global).code = "ENOTVALID";
}
/**
 * Inherit from `Error`.
 */


NotValidError.prototype.__proto__ = Error.prototype;
/**
 * Expose `NotValidError`.
 */

exports = NotValidError;
export default exports;